import React, { useState, useEffect } from "react";
import Navigation from '../components/Navigation';
const Lotto = () => {

  const [data, setData] = useState([]);

  const fetchData = () => {
    fetch('https://api.brettbartholomew.com')
      .then((response) => response.json())
      .then((actualData) => {
        //console.log(actualData);
        setData(actualData.games);
        //console.log(data);
      })
      .catch((err) => {
        //console.log(err.message);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <div className="Main">
      <Navigation />
      <h3>
        Remaining
      </h3>
      <table className="table table-striped table-primary">
      <tbody>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Price</th>
          <th>MaxWin</th>
          <th>Delta</th>
          <th>Date</th>
        </tr>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.name}</td>
            <td>{item.type}</td>
            <td>{item.price}</td>
            <td>{item.maxprize}</td>
            <td>{item.diff}</td>
            <td>{item.date}</td>
          </tr>
        ))}
      </tbody>
      </table>
    </div>
  );
};

export default Lotto;