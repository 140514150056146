import React from "react";
import {
 Navbar,
 Nav,
 Button,
 Container
} from 'react-bootstrap'
 
const Navigation = () => {
 
 return (
   <Navbar bg="secondary" variant="dark" expand="md" className="nav">
    <Container>
     <Navbar.Brand href="/">BRETT BARTHOLOMEW</Navbar.Brand>
     <Navbar.Toggle aria-controls="basic-navbar-nav" />
     <Navbar.Collapse id="basic-navbar-nav">
       <Nav className="w-100 justify-content-end">
         <Button href="/contact">CONTACT</Button>
       </Nav>
     </Navbar.Collapse>
     </Container>
   </Navbar>
 );
};
export default Navigation;