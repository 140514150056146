import { Route, Routes } from 'react-router-dom';
import './assets/App.css';
import Home from './views/Home';
import Lotto from './views/Lotto';
import Contact from './views/Contact';

function App() {
  return (
    <div className="App">
       <Routes>
         <Route path='/' element={<Home/>} />
         <Route path='/lotto' element={<Lotto/>} />
         <Route path='/contact' element={<Contact/>} />
       </Routes>
    </div>
  )
}

export default App;
