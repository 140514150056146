import React from "react";
import Navigation from '../components/Navigation';
const Contact = () => {

  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", process.env.REACT_APP_W3ID);

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Message Sent!");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  
  return (
    <div className="Main">
      <div>
        <Navigation />
      </div>
      <div>
        <div className="row">
          <div className="col-md-6 offset-md-3 mt-3 bg-light p-3 border border-secondary">
            <form className="row g-3" onSubmit={onSubmit}>
              <div className="col-md-6">
                <label className="form-label">Your Name</label>
                <input type="text" className="form-control bg-primary" name="name" placeholder="Enter name" required />
              </div>
              <div className="col-md-6">
                <label className="form-label">Your Email</label>
                <input type="email" className="form-control bg-primary" name="email" placeholder="Enter email" required />
              </div>
              <div className="col-md-12">
                <label className="form-label">Message</label>
                <textarea className="form-control bg-primary" name="message" placeholder="Enter your message" rows="5" required></textarea>
              </div>
              <div className="col-md-12">
                <input type="submit" className="btn btn-primary" value="Send" />
              </div>
            </form><br />
            <span>{result}</span>
          </div>
        </div>
      </div>
    </div>
)}

export default Contact;
